.Btn {
    --color: var(--white);
    --icon-color: var(--white);
    --background-color: var(--primary);
    --border: none;
    --line-gap: var(--gap-s);
}

.Btn {
    background-color: var(--background-color);
    padding: var(--gap-s) var(--gap-m);
    border-radius: var(--radius);
    color: var(--color);
    font-weight: var(--font-weight-bold);
    border: var(--border);
    font-size: 1rem;
    white-space: nowrap;
    min-height: 3.5rem;
    transition: background-color 0.3s, color 0.3s;
}



.Btn .text {
    margin: 0 auto;
}

.Btn.no-children {
    padding: var(--gap-s);
}
.Btn:hover {
    cursor: pointer;
    --background-color: var(--primary-hover);
}

.Btn:active {
    --background-color: var(--primary-active);
}

.Btn.link-btn {
    background-color: transparent;
    color: var(--background-color);
    padding: 0;
    min-height: auto;
    display: inline-block;
}

.Btn.light-btn {
    background-color: transparent;
    color: var(--black);
    padding: 0 1rem;
}

.Btn.light-btn:disabled {
    color: var(--gray);
    cursor: default;
}

.Btn.light-btn svg {
    color: var(--background-color);
}

.Btn.secondary-btn {
    background-color: var(--primary-opac);
    color: var(--primary);
    padding: .8rem 1rem;
    min-height: auto;
    border-radius: 13px;
}

.Btn.secondary-btn:disabled {
    color: var(--gray);
    cursor: default;
}

.Btn.secondary-btn svg {
    color: var(--background-color);
}

.Btn.cancel-btn {
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid var(--primary);
}


.Btn > .Line {
    gap: var(--line-gap);
}

.Btn path {
    --fill: var(--icon-color)
}

.Btn svg {
    width: 20px;
}

.Btn:disabled,
.Btn:disabled:hover,
.Btn:disabled:active {
    --background-color: var(--gray);
    --color: var(--gray-text);
    --icon-color: var(--gray-text);
}

.Btn[data-type="whatsapp"] {
    --color: #09D785;
    --background-color: #fff;
    --border: 2px solid var(--color);
    --icon-color: var(--color);
}

.Btn[data-type="whatsapp"]:hover {
    --background-color: #09d78549;
}

.Btn[data-type="invert"] {
    --color: var(--primary);
    --background-color: #fff;
    --border: 2px solid var(--color);
    --icon-color: var(--color);
}

.Btn[data-type="invert"]:hover {
    background-color: var(--primary-opac);
}

.Btn[data-type="card"] {
    --color: #858585;
    --primary-opac: var(--gray-opac);
    --background-color: var(--gray);
    --border: none;
    --icon-color: #858585;
    --line-gap: var(--gap-xs);

    padding: 0.1rem 0.5rem;
    font-weight: 100;
    min-height: auto;
}

.Btn[data-type="card"]:hover {
    background-color: var(--primary-opac);
}

.Btn[data-type="card"].selected {
    background-color: var(--primary);
    --color: var(--white);
    --icon-color: var(--white);
}

.Btn[data-type="link"] {
    --color: var(--primary);
    --background-color: transparent;
    --border: none;
    --icon-color: var(--primary);
    padding: 0;
    min-height: auto;
}

.Btn[data-type="link"]:hover {
    --color: var(--primary-hover);
}

.Btn[data-type="link"]:active {
    --color: var(--primary-active);
}

.Btn[data-type="secondary"] {
    --color: var(--black);
    --background-color: var(--white);
    --border: none;
    --icon-color: var(--primary);
    min-height: auto;
    font-weight: 400;
    padding: var(--gap-s);
}

.Btn[data-type="secondary"] path {
    --fill: var(--black);
}

.Btn[data-type="secondary"]:hover {
    --background-color: var(--white-hover);
}

.Btn[data-type="secondary"]:active {
    --background-color: var(--white-active);
}

.BtnBig {
    border: 1px solid var(--background-gray);
    padding: var(--gap-s);
    border-radius: 10px;
}

.BtnBig:hover {
    background-color: var(--white-hover);
    cursor: pointer;
}

.BtnBig:active {
    background-color: var(--white-active);
}

.BtnBig svg {
    background-color: var(--primary-border);
    color:var(--white);
    padding: var(--gap-p);
    border-radius: 50%;
}

.Btn .actions-wrap {
    position: relative;
    overflow: hidden;
    height: 20px;
    width: 20px;
}

.Btn .actions-wrap > * {
    position: absolute;
    top:50%;
    left: 50%;
    transition: 0.3s transform, 0.3s opacity;
}

.Btn .actions-wrap .loader{
    transform: translateY(-50%) translateX(100%);
    opacity: 0;
}

.Btn .actions-wrap .next-icon{
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    display: flex;
}

.Btn.loading .actions-wrap .loader{
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    display: flex;
}

.Btn.loading .actions-wrap .next-icon{
    transform: translateY(-50%) translateX(100%);
    opacity: 0;
}

.Touchable {
    cursor: pointer;
    width: fit-content;
}

.Touchable:hover {
    opacity: 0.8;
}

.Touchable:active {
    opacity: 0.9;
}

.Btn.info-btn {
    background-color: #fff;
    color: #3B3B3B;
    font-style: italic;
    font-weight: 100;
    cursor: default;
}

.Btn.info-btn > .Line {
    margin: 0;
    justify-content: center;
}

.Btn.info-btn .text {
    margin: 0;
}