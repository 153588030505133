.Questions {
    
}

.Questions .question {
    animation-name: loading;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: scale(0);
}

.Questions  > .list {
    padding: var(--gap-m);
}

.Questions > .actions {
    margin-top: var(--gap-m);
    border-top: 1px solid #e1e1e1;
    padding: var(--gap-m);
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.Questions > .actions > * {
    flex: 1;
}

.Questions .question:has(.Dropdown) {
    z-index: 10;
}

.Questions .question .Dropdown {
    width: auto;
}

@keyframes loading {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}