html {
  font-size: var(--font-size);
}

html,
body,
#root,
.App {
  height: 100%;
}

body {
  overflow-y: scroll;
}

* {
  margin: 0;
}

body,
input,
button,
textarea {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  color: var(--color);
  background-color: var(--bakground);
}

.App[data-mode="dark"] {
  --color: var(--white);
  --bakground: var(--natural);
  color: var(--color);
  background-color: var(--bakground);
}

a {
  color: var(--primary);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  font-size: 1.1rem;
}

a:hover {
  color: var(--primary-hover);
}

a:active {
  color: var(--primary-active);
}

a.contents {
  display: contents;
}

.Schedule .lang_bold {
  font-weight: bold;
}

.Schedule[data-risk="high"] .lang_bold {
  color: var(--high);
}

.Schedule[data-risk="low"] .lang_bold {
  color: var(--low);
}