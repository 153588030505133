.Datepicker {
    display: grid;
    gap: var(--gap-xs);
    text-align: center;
    grid-template-columns: 1fr 1fr 2fr;
}

.Datepicker>div {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
}

.Datepicker>div>h4 {
    align-self: flex-start;
    padding-left: var(--gap);
}