/* large screen, larger then 992 */
@media screen and (min-width: 992px) {}

/* medium screens, smaller then 992 */

@media screen and (max-width: 992px) {}

/* small screens, smaller then 800px */

@media screen and (max-width: 800px) {
    
    .Header [data-media="dropdown-icon"] .Line.between p {
        display: none;
    }

    .Header [data-media="dropdown-icon"] .Line.between>svg {
        display: none;
    }

    .Header [data-media="dropdown-icon"] .list {
        width: 200px;
        right: 0;
    }

    .Step {
        width: 100%;
        margin-top: var(--gap);
        max-width: 500px;
    }

    .Steps[data-media="steps"] > .wrap > div {
        width: 100%;
    }

    .Steps[data-media="steps"] .Cube {
        width: 56px;
        height: 60px;
    }

    .Steps[data-media="steps"] .Cube>.icon>svg {
        width: 35px;
        height: 35px;
    }

    .Steps[data-media="steps"] .Cube>.title h4 {
        font-size: 9px;
        line-height: 11px;
    }

    .CheckBox {
        min-width: auto;
    }

    .Popup>.screen {
        padding: 1rem;
        width: calc(100% - 1rem);
        left: -1rem;
    }

    .Popup>.screen>.content {
        max-width: none;
        min-width: auto;
        width: calc(100vw - 4rem);
        padding: var(--gap-s);
    }

    .Support {
        width: 100%;
    }

    .Support .Accordion h3 {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }

    .Icon[data-size="medium"]>svg {
        width: 95px;
        height: auto;
    }

    .MarkdownShow {
        width: 100%;
    }

    body .Legals > .top,
    body .Legals > .bottom {
        grid-template-columns: 1fr;
    }
}

/* smaller screens, smaller then 500px */

@media screen and (max-width: 500px) {
    .Header [data-media="btn"] {
        padding: 0;
    }

    .Header [data-media="btn"] .text {
        display: none;
    }
}

.Header :has(> [data-media="btn"]) {
    container-type: inline-size; 
    width: 100%;
    flex-wrap: nowrap;
}

@container (inline-size < 380px) {
    .Header [data-media="btn"] {
        padding: 0;
    }

    .Header [data-media="btn"] .text {
        display: none;
    }
}


/* smaller screens, smaller then 400px */

@media screen and (max-width: 400px) {
    html {
        font-size: 14px;
    }
}