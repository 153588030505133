.Steps {
    display: flex;
    flex-direction: column;
    gap: var(--gap-s);
    width: 100%;
}

.Steps > .wrap {
    position: relative;
    color: var(--primary);
    display: flex;
    justify-content: space-between;
}

.Steps > .wrap > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    z-index: 1;
}

/* start */
.Steps.start > .wrap:nth-child(2)::before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #01A5AF 0%, rgba(84, 192, 226, 0.48) 39.31%, #C2D1D9 42.58%);
    width: 100%;
    height: 2px;
    top: 7px;
    z-index: 0;
}

.Steps.start > .wrap:nth-child(2) > div:nth-child(2),
.Steps.start > .wrap:nth-child(2) > div:nth-child(3) {
    color: var(--gray-text);
}

.Steps.start > .wrap:nth-child(3) > div {
    --font-weight-bold: 100;
}

.Steps.start > .wrap:nth-child(3) > div:nth-child(1) {
    --font-weight-bold: bold;
    color: var(--black);
}

.Steps.start > .wrap:nth-child(3) > div:nth-child(2),
.Steps.start > .wrap:nth-child(3) > div:nth-child(3) {
    color: var(--gray-text);
}

/* summary */

.Steps.summary > .wrap:nth-child(2)::before {
    content: "";
    position: absolute;
    background: linear-gradient(90deg, #01A5AF 0%, rgba(84, 192, 226, 0.48) 45.31%, #C2D1D9 89.58%);
    width: 100%;
    height: 2px;
    top: 7px;
    z-index: 0;
}

.Steps.summary > .wrap:nth-child(2) > div:last-child {
    color: var(--gray-text);
}

.Steps.summary > .wrap:nth-child(3) > div {
    --font-weight-bold: 100;
}

.Steps.summary > .wrap:nth-child(3) > div:nth-child(2) {
    --font-weight-bold: bold;
    color: var(--black);
}

.Steps.summary > .wrap:nth-child(3) > div:nth-child(3) {
    color: var(--gray-text);
}