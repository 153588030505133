.Input {
    background-color: var(--white);
    display: flex;
    border: 1px solid var(--input);
    border-radius: var(--radius);
    min-height: 3.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--gap);
    box-shadow: 0px 0px 0px 0px var(--primary);
    transition: .2s box-shadow;
}

.Input input {
    color: var(--input);
    border: none;
    border-radius: inherit;
    padding: var(--gap-s);
    flex: 1 1;
    color: var(--natural);
    font-size: var(--font-size-m);
    width: calc(100% - (var(--gap-s) * 2));
    padding-left: 0;
}

.Input[data-error="true"] {
    border-color: var(--error);
}

.Input:has(input:focus) {
    border-color: var(--primary);
    box-shadow: 0px 0px 0px 1px var(--primary);
}

::placeholder {
    color: var(--input);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--input);
}

::-ms-input-placeholder {
    color: var(--input);
}

textarea:focus, input:focus{
    outline: 0 none;
}

.PhoneInput {
    flex: 1
}

.PhoneInput .PhoneInputCountry {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--gap);
}

.Input .is_copy.selected {
    color: var(--primary);
}