.Inner {
    max-width: var(--inner-width);
    margin: 0 auto;
    width: 100%;
}

.Inner.small {
    max-width: 800px;
}

.Inner.side {
    margin: 0;
}