.MultiCheck {
    width: 100%;
}

.CheckBox {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: var(--gap);
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--primary-border);
    min-width: 150px;
    cursor: pointer;
    font-size: var(--font-size-m);
}

.CheckBox:hover:not([data-no-hover="true"]) {
    cursor: pointer;
    opacity: 0.8;
}

.CheckBox:active:not([data-no-hover="true"]) {
    opacity: 0.9;
}

.CheckBox[data-disabled="true"], 
.CheckBox[data-disabled="true"]:hover {
    opacity: 0.3;
    cursor: default;
}

.CheckBox[data-error="true"] {
    border-color: var(--error);
}

.CheckBox[data-checked="true"] {
    border: 1px solid #01A5AF;
    background: rgba(1, 165, 175, 0.16);
}

.CheckBox .icon_wrap {
    width: 16px;
    height: 16px;
    border: 1px solid #333;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s box-shadow;
    flex-shrink: 0;
}

.CheckBox[data-type="radio"] .icon_wrap {
    border-radius: 50%;
}


.CheckBox[data-checked="true"] .icon_wrap {
    background-color: var(--primary);
    color: var(--primary);
    border: 2px solid var(--primary);
    box-shadow: 1px 1px 7px #6d6c6c
}

.CheckBox.dis {
    opacity: 0.3;
    cursor: default;
}

.CheckBox[data-theme="short"] {
    --border-color: var(--primary);
    font-size: 1rem;
}

.CheckBox[data-validation="true"] {
    color: red;
}



.CheckBox input {
    position: absolute;
    opacity: 0;
}

.CheckBox:has(input:focus) {
    outline: 1px solid var(--natural);
}

.CheckBox[data-theme="action"],
.CheckBox[data-theme="action"]:has(input:focus) {
    background-color: transparent;
    outline: none;
    border: none;
}
