.Cube {
    box-shadow: 0px 2px 8px 0px #00000026;
    width: 115px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px;
}

.Cube>.icon>svg {
    width: 45px;
    height: 45px;
}

.Cube>.title {
    text-align: center;
    font-weight: 100;
}

.Cube[data-variant="done"] {
    box-shadow: 0px 0px 0px 2px var(--primary);
    background-color: #E5F7F7;
    color: var(--primary);
}

.Cube[data-variant="default"] .icon {
    color: var(--primary);
}

.Cube[data-variant="default"] .title {
    --font-weight-regular: bold;
    color: var(--black);
}

.Cube[data-variant="next"] {
    box-shadow: 0px 0px 0px 2px var(--gray-text);
    opacity: 0.8;
    background-color: var(--white);
    color: var(--gray-text);
}

.Cube[data-variant="later"] {
    box-shadow: 0px 0px 0px 2px var(--gray-text);
    opacity: 0.5;
    background-color: var(--white);
    color: var(--gray-text);
}