.Line {
    display: flex;
    gap: var(--gap);
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.Line.no-wrap {
    flex-wrap: nowrap;
}

.Line.short {
    gap: var(--gap-xs);
}

.Line.end {
    justify-content: flex-end;
    width: 100%;
}

.Line.between {
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}

.Line.center,
.Line.center-align {
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Line.center-align {
    text-align: center;
    height: auto;
}

.Line.spread {
    width: 100%;
}

.Line.spread>*:first-child {
    flex-grow: 1;
}

.Line.spread-end {
    width: 100%;
}

.Line.spread-end>*:last-child {
    flex-grow: 1;
}

.Line.rows {
    flex-direction: column;
    align-items: baseline;
    gap: var(--gap-s);
}

.Line.rows.short {
    gap: var(--gap-xs);
}

.Line.rows.full {
    align-items: stretch;
}

.Line.rows.high {
    gap: var(--gap-m);
}

.Line.rows.center {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.Line.center>.Line,
.Line.rows>.Line {
    height: auto;
}

.Line.self-start {
    align-self: flex-start;
}


.Line.self-end {
    align-self: flex-end;
}

.Line.spread-even > * {
    flex: 1;
}

.Line.even-between {
    justify-content: space-evenly;
}

.Line.even-between > * {
    flex: 1;
    display: flex;
}

.Line.even-between > :nth-child(2) {
    justify-content: center;
}

.Line.even-between > :first-child {
    justify-content: flex-start;
}

.Line.even-between   > :last-child {
    justify-content: flex-end;
}