.Box {
    border: 2px solid var(--primary);
    background-color: var(--white);
    position: relative;
    border-radius: 10px;

    min-height: 400px;
}

.Box .sep {
    position: absolute;
    display: flex;
    background-color: var(--white);
    height: 3px;
    width: 20px;
}

.Box .sep::after, 
.Box .sep::before {
    position: absolute;
    left: -1px;
    top:-1px;
    width: 3px;
    height: 3px;
    border: 1px solid var(--primary);
    border-radius: 50%;
    content: '';
}

.Box .sep::before  {
    left: auto;
    right: -1px;
}

.Box .sep:nth-child(1) {
    transform: rotate(90deg);   
    transform-origin: 0 0;
    left: 0px;
    width: 60px;
    top: 140px;
}

.Box .sep:nth-child(2) {
    transform: rotate(-90deg);   
    transform-origin: bottom right;
    top: 180px;
    right: -2px;
    width: 120px;
}

.Box .sep:nth-child(3) {
    transform: rotate(0deg);   
    transform-origin: center center;
    bottom: -2px;
    left: 40px;
    width: 120px;
}