.Icon {}

.Icon>svg {
    height: 24px;
    color: var(--primary);
}

.Icon[data-size="medium"]>svg {
    height: 45px;
}

.Icon.action:hover {
    cursor: pointer;
    opacity: 0.8;
}

.Icon.action:active {
    opacity: 0.9;
}

.Icon[data-visibility="false"] {
    visibility: hidden;
}

.Logo {
    width: 7rem;
}

.Loader {
    width: 12rem;
}

.Loader img {
    width: 100%;
}

.icon_text {
    position: relative;
    width: fit-content;
}

.icon_text .text {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap:var(--gap);
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
    font-variant: all-small-caps;
}

.icon_text .text p {
    line-height: 0;
    font-size: var(--font-size-l);
}