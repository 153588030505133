.Step {
    width: 32rem;
    margin-top: var(--gap-xl);
    flex: 1;
}

.Step .Box {
    
}

.Step .Box > .header {
    padding: var(--gap-l);
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px 30px;
}

.Step .Box > .bar {
    height: 3px;
    position: relative;
    background-color: var(--gray);
}

.Step .Box > .bar .per {
    position: absolute;
    background-color: var(--primary);
    height: inherit;
    transition: .6s width;
}