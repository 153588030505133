.Header {
    background-color: rgb(255 255 255 / 50%);
    padding: 0 var(--gap-m);
    display: flex;
}

.Header .Line.even-between > * {
    flex: 1;
    display: flex;
    align-items: center;
}

.Header .Line.even-between > :nth-child(1) {
    justify-content: flex-start;
}

.Header .Line.even-between > :nth-child(2) {
    justify-content: center;
}

.Header .Line.even-between > :nth-child(3) {
    justify-content: flex-end;
}