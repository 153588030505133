.Accordion {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin-bottom: var(--gap);
}

.Accordion .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--primary);
    padding: var(--gap-xs) var(--gap-s);
}

.Accordion .header:hover{
    cursor: pointer;
    opacity: 0.8;
    background-color: #f9f9f9;
}

.Accordion .content {
    max-height: 0;
    transition: max-height .2s ease-out;
    overflow: hidden;
}

.Accordion .content .inner {
    padding: var(--gap-xs) var(--gap-s);
    border-top: 1px solid #e1e1e1;
}

.Accordion[data-open="true"] .content {
    max-height: 500px;
    transition: max-height .5s ease-in;
}

.Accordion .icon {
    transform: rotate(-90deg);
    transition: transform .2s ease-out;
}

.Accordion[data-open="true"] .icon {
    transform: rotate(0deg);
    transition: transform .2s ease-in;
}