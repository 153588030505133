.HomeLayout {
    position: relative;
    height: 100%;
}

.HomeLayout>* {
    position: absolute;
    z-index: 1;
}

.HomeLayout > .bg {
    z-index: 0;
    background: rgba(241, 247, 250, 0.20);
    filter: blur(150px);
    -webkit-filter: blur(150px);
    -webkit-backdrop-filter: blur(150px);
    width: 100%;
    height: 100%;
}

.HomeLayout > .bg img {
    width: 98%;
    height: 98%;
}

.HomeLayout > .bg img {
    left: 0;
    position: absolute;
    object-fit: fill;
    top: 0;
}

.HomeLayout > .content {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 5rem 1fr;
}

.HomeLayout > nav {
    border: 1px solid rgba(230, 240, 245, 0.50);
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(50px);
}

.HomeLayout main {
    padding: var(--gap);
    width: calc(100% - (var(--gap) * 2));
}