.Padding {
    padding: var(--gap-m);
}

.Padding-top {
    padding-top: var(--gap-m);
}

.Margin {
    margin-bottom: var(--gap);
    width: 100%;
}

.Margin-top {
    margin-top: var(--gap);
    width: 100%;
}

.Margin-big {
    margin-bottom: var(--gap-l);
    width: 100%;
}

.Padding-big {
    padding: var(--gap-m) var(--gap-xl);
}