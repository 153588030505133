h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-weight: var(--font-weight-regular);
    font-style: normal;
    color: var(--primary-primary-0);
    white-space: pre-wrap;
}

h1 {
    font-size: 2.2rem;
    line-height: 2.5rem; 
}

h2 {
    font-size: 1.8rem;
    line-height: 3.25rem;
}

h3 {
    font-size: 1.4rem;
    line-height: 2.5rem;
}

h4 {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: var(--font-weight-regular);
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
}

[data-weight="light"] {
    font-weight: var(--font-weight-light);
}

[data-weight="regular"] {
    font-weight: var(--font-weight-regular);
}

[data-weight="medium"] {
    font-weight: var(--font-weight-medium);
}

[data-weight="bold"] {
    font-weight: var(--font-weight-bold);
}


[data-align="left"] {
    text-align: left;
}

[data-align="right"] {
    text-align: right;
}

[data-align="center"] {
    text-align: center;
    margin: 0 auto;
}
[data-skin="error"] {
    color: var(--error);
}
[data-skin="gray"] {
    color: var(--gray-text);
}
[data-skin="mark"] {
    color: var(--primary);
}

[data-indent="true"] {
    padding-left: var(--gap);
}

