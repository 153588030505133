.Dropdown {
    width: fit-content;
    position: relative;
}

.Dropdown[data-spread="true"] {
    width: auto;
    border: 1px solid var(--stroke);
}

.Dropdown>.header {
    padding: var(--gap-s) var(--gap-s);
    border-radius: 0.5rem;
    background-color: var(--white);
}

.Dropdown[data-has-border="true"]>.header {
    border: 1px solid var(--primary-border);
}

.Dropdown>.header:hover {
    cursor: pointer;
    background-color: var(--light-gray);
}

.Dropdown[data-error="true"]>.header {
    border-color: var(--error);
}

.Dropdown>.header *:hover {
    cursor: pointer;
    background-color: var(--light-gray);
}

.Dropdown>.header:active {
    background-color: var(--white);
}

.Dropdown>.list {
    border: 1px solid var(--gray);
    position: absolute;
    top: 100%;
    min-width: 100%;
    width: max-content;
    box-sizing: border-box;
    z-index: 1;
    background-color: var(--white);
    box-shadow: 1px 6px 9px #9d9d9d;
    overflow: auto;
    height: 250px;
}

.Dropdown>.list>* {
    padding: var(--gap-s);
    background-color: var(--white);
    border-bottom: 1px solid var(--gray);
}

.Dropdown>.list>*.selected {
    background-color: var(--gray);
}

.Dropdown>.list>*:hover {
    cursor: pointer;
    background-color: var(--gray-opac);
}

.Dropdown>.list>*:active {
    background-color: var(--white);
}