.Grid {
    display: grid;
    gap: var(--gap-xs);
}

.Grid.triple {
    grid-template-areas:
        "a b"
        "c c"
    ;
}

.Grid.triple> :nth-child(3) {
    grid-area: c;
}

.Grid.split {
    grid-template-columns: 1fr 1fr;
}

.Grid.triple-up {
    grid-template-columns: 1fr 1fr;
}

.Grid.triple-up> :first-child {
    grid-column-start: 1;
    grid-column-end: 3;
}